<template>
  <app-layout>
    <div class="page">
      <img src="./images/steps.png" alt="" class="steps" />
      <div class="title">
        <img
          src="./images/cross.png"
          alt=""
          style="width: 56px; height: 103px"
        />
        <div class="title-text">请选择场景</div>
      </div>
      <div class="swiper-list">
	      <div class="swiper-left-button" @mouseover="mouseoverLeft" @mouseout="mouseoutLeft" @click="useSwiper.slidePrev()">
		      <img
				      v-show="isMouseoverLeft"
				      src="./images/left_hover.png"
				      alt=""
				      class="swiper-left"
		      />
		      <img
				      v-show="!isMouseoverLeft"
				      src="./images/left.png"
				      alt=""
				      class="swiper-left"
		      />
	      </div>
        <swiper
          :slidesPerView="5"
          :loop="true"
          :centeredSlides="true"
          :initialSlide="0"
          class="swiper-class"
          @swiper="onSwiper"
          @activeIndexChange="activeIndexChange"
        >
          <swiper-slide v-for="(item, index) in scheneImgList" :key="item.id">
            <div :class="swiperCurrent != index ? 'swiper-shade' : ''"></div>
            <img
              :src="item"
              alt=""
              :class="
                swiperCurrent == index ? 'swiper-img-active' : 'swiper-img'
              "
            />
          </swiper-slide>
        </swiper>
	      <div class="swiper-right-button" @mouseover="mouseoverRight" @mouseout="mouseoutRight" @click="useSwiper.slideNext()">
		      <img
				      v-show="isMouseoverRight"
				      src="./images/right_hover.png"
				      alt=""
				      class="swiper-right"
		      />
		      <img
				      v-show="!isMouseoverRight"
				      src="./images/right.png"
				      alt=""
				      class="swiper-right"
		      />
	      </div>
      </div>
      <div style="position: relative; padding-top: 90px">
        <img src="./images/cross.png" alt="" class="button-cross" />
        <div class="button" @click="clickNextStep">下一步</div>
      </div>
    </div>
    <Dialog
      :dialogShow="dialogShow"
      :sceneContainer="sceneContainer"
      @closeDialog="closeDialog"
    ></Dialog>
  </app-layout>
  <home-backup />
</template>

<script setup>
import AppLayout from "@/components/AppLayout.vue";
import HomeBackup from "@/views/home/components/HomeBackup.vue";
import Dialog from "./components/Dialog.vue";
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue"; // swiper 所需组件
import "swiper/swiper-bundle.css";
import { ref } from "vue";
import { softwareIndustry } from "@/api/Home";
import { ElMessage } from "element-plus";

const scheneImgList = ref([
  require("./images/shangwu.png"),
  require("./images/neirong.png"),
  require("./images/jianmo.png"),
  require("./images/AI.png"),
  require("./images/zhibo.png"),
  require("./images/shengchan.png"),
]);

let isMouseoverLeft = ref(false)
let isMouseoverRight = ref(false)
//鼠标移入左侧按钮
const mouseoverLeft = () => {
	isMouseoverLeft.value = true
}
// 鼠标移出左侧按钮
const mouseoutLeft = () => {
	isMouseoverLeft.value = false
}
const mouseoverRight = () => {
	isMouseoverRight.value = true
}
const mouseoutRight = () => {
	isMouseoverRight.value = false
}

let useSwiper = null;
// 初始化swiper
const onSwiper = (swiper) => {
  useSwiper = swiper;
};

let swiperCurrent = ref(); // 当前轮播图
// 轮播图切换
const activeIndexChange = (swiper) => {
	swiperCurrent.value = swiper.realIndex
  /*if (swiper.activeIndex - 5 == 6) {
    swiperCurrent.value = 0;
  } else if (swiper.activeIndex - 5 == -1) {
    swiperCurrent.value = 5;
  } else {
    swiperCurrent.value = swiper.activeIndex - 5;
  }*/
};

const dialogShow = ref(false); // 弹出窗是否显示
let sceneContainer = ref([]); // 场景内容列表
// 点击下一步
const clickNextStep = () => {
  const domainParent = swiperCurrent.value + 1;
  softwareIndustry(domainParent).then((res) => {
    if (res.code == 200) {
      sceneContainer.value = res.data;
      dialogShow.value = true;
    } else {
      ElMessage({
        message: res.message,
        type: "success",
      });
    }
  });
};

const closeDialog = () => {
  dialogShow.value = false;
};
</script>

<style scoped lang="less">
.page {
	user-select:none;
  background-color: #f8f8f8;
	padding-top: 90px;
  padding-bottom: 67px;
  .steps {
    margin: 0 auto;
    display: block;
    width: 943px;
    height: 60px;
  }
  .title {
    display: flex;
    padding-top: 20px;
    margin-left: 294px;
    .title-text {
      margin-top: 25px;
      margin-left: 517px;
      height: 35px;
      font-family: PingFang SC;
      font-weight: 400;
      font-size: 36px;
      color: #000000;
      line-height: 17px;
    }
  }
  .swiper-list {
    display: flex;
    align-items: center;
    width: 71%;
    margin: 0 auto;
	  .swiper-left-button{
		  width: 37px;
		  height: 37px;
		  cursor: pointer;
		  position: relative;
		  /*&:after{
			  content:"";
			  position:absolute;
			  top:-100px;
			  left:-10px;
			  right:-250px;
			  bottom:-100px;
			  z-index: 99;
		  }*/
		  .swiper-left {
			  width: 37px;
			  height: 37px;
			  cursor: pointer;
		  }
	  }

    .swiper-class {
      width: 1280px;
      margin-left: 10px;
      margin-right: 0;
      padding-right: 40px;
      ::v-deep .swiper-slide-active {
        width: 280px !important;
        margin-right: 13px;
      }
      .swiper-shade {
        position: absolute;
        margin-top: 22%;
        width: 231px;
        height: 394px;
        background-color: rgba(0, 0, 0, 0.5);
        border-radius: 116px;
      }
      .swiper-img-active {
        width: 280px;
        height: 469px;
        border-radius: 140px;
        border: 4px solid #d62828;
      }
      .swiper-img {
        width: 231px;
        height: 394px;
        margin-top: 22%;
      }
    }


	  .swiper-right-button {
		  width: 37px;
		  height: 37px;
		  cursor: pointer;
		  position: relative;
		  /*&:after {
			  content: "";
			  position: absolute;
			  top: -100px;
			  left: -250px;
			  right: -10px;
			  bottom: -100px;
			  z-index: 99;
		  }*/

		  .swiper-right {
			  width: 37px;
			  height: 37px;
			  cursor: pointer;
		  }
	  }
  }
  .button-cross {
    position: absolute;
    width: 56px;
    height: 103px;
    margin-left: 80%;
    margin-top: -4%;
  }
  .button {
    margin: 0 auto;
    width: 638px;
    height: 88px;
    line-height: 88px;
    background: #000000;
    border-radius: 44px;
    text-align: center;
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 28px;
    color: #ffffff;
    cursor: pointer;
  }
}
</style>
