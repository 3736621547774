<template>
  <el-dialog
    v-model="props.dialogShow"
    :before-close="handleClose"
    :show-close="false"
    width="52%"
    align-center
  >
	  <template #header>
		  <div class="dialog-title">
			  <div></div>
			  <div style="padding-left: 39px;">{{sceneName}}</div>
			  <div style="padding-right: 39px;cursor:pointer;" @click="handleClose"><img src="../images/close.png" alt="" style="width: 28px;height: 28px;"></div>
		  </div>
	  </template>
    <div class="dialog-container">
      <div
        v-for="item in props.sceneContainer"
        class="schene-li"
        @click="clickschene(item.id)"
      >
        <div class="schene-img">
          <img
            :src="globalVariable.ossPath + '/goods' + item.logo"
            alt=""
            class="schene-img"
          />
        </div>
        <div class="schene-li-text">
          <div class="schene-li-text1">{{ item.domainName }}</div>
          <div class="schene-li-text2">{{ item.domainContent }}</div>
        </div>
        <div>
          <img
            v-show="industryId == item.id"
            src="../images/checked.png"
            alt=""
            class="schene-li-button"
          />
          <img
            v-show="industryId != item.id"
            src="../images/unChecked.png"
            alt=""
            class="schene-li-button"
          />
        </div>
      </div>
    </div>
    <div class="dialog-bottom">
      <div class="dialog-button" @click="clickConfirm">确定</div>
    </div>
  </el-dialog>
</template>

<script setup>
import { defineEmits, defineProps, ref, watch } from "vue";
import globalVariable from "@/global-variable";
import {useRouter} from "vue-router";

const router = useRouter()
const props = defineProps(["dialogShow", "sceneContainer", "ontainer"]);
const emit = defineEmits(["closeDialog"]);

let industryId = ref(); // 当前选择的场景
let sceneName = ref(); // 场景名
watch(props, (newId, oldId) => {
  industryId.value = props.sceneContainer[0].id;
	const domainParent = props.sceneContainer[0].domainParent;
	switch (Number(domainParent)) {
		case 1:
			sceneName.value = '商务办公应用'
			break
		case 2:
			sceneName.value = '内容创作应用'
			break
		case 3:
			sceneName.value = '建模制图应用'
			break
		case 4:
			sceneName.value = 'AI算力应用'
			break
		case 5:
			sceneName.value = '直播推流应用'
			break
		case 6:
			sceneName.value = '生产力应用'
			break
	}
});

// 关闭弹出框
const handleClose = () => {
  emit("closeDialog");
};

// 点击选择场景
const clickschene = (id) => {
  industryId.value = id;
};

// 点击确定
const clickConfirm = () => {
	router.push({
		path: "/confirmConfig",
		query: {
			industryId: industryId.value
		}
	})
};
</script>

<style lang="less">
.el-dialog {
  background-color: #f8f8f8;
  border-radius: 10px;
}
.el-dialog__body {
  padding: 0 ;
}
.el-dialog__header{
	padding:0;
	margin-right: 0;
}
.dialog-title{
	display: flex;
	justify-content: space-between;
	padding-top: 30px;
	height: 100px;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	background-color: #fff;
	font-family: PingFang SC;
	font-weight: 500;
	font-size: 32px;
	color: #000000;
}
.dialog-container {
	margin-top: 9px;
  height: 631px;
  width: 100%;
  background-color: #f8f8f8;
  overflow-y: auto;
  overflow-x: visible;
  .schene-li {
    display: flex;
    margin: 0 auto;
    margin-bottom: 12px;
    width: 964px;
    background-color: #fff;
	  cursor: pointer;
    .schene-img {
      width: 200px;
      height: 120px;
      object-fit: cover;
	    border-radius: 2%;
    }
    .schene-li-text {
      margin-top: 21px;
      margin-left: 17px;
      .schene-li-text1 {
        height: 22px;
        line-height: 22px;
        font-family: PingFang SC;
        font-weight: 500;
        font-size: 22px;
        color: #000000;
      }
      .schene-li-text2 {
        margin-top: 9px;
        width: 561px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        font-family: PingFang SC;
        font-weight: 400;
        font-size: 18px;
        color: #808490;
        line-height: 30px;
      }
    }
    .schene-li-button {
      margin-top: 79px;
      margin-left: 145px;
      width: 24px;
      height: 24px;
    }
  }
}
.dialog-bottom {
  padding-top: 20px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  width: 100%;
  height: 100px;
  background-color: #ffffff;
  .dialog-button {
    margin: 0 auto;
    width: 216px;
    height: 60px;
    line-height: 60px;
    background: #000000;
    border-radius: 10px;
    text-align: center;
    font-family: PingFang SC;
    font-weight: 400;
    font-size: 24px;
    color: #ffffff;
	  cursor:pointer;
  }
}
</style>
